<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-down">Blog</h1>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-up">
        <div class="text-info">
          <div class="text-info-author">
            <img src="@/assets/icons/my_profile.svg" alt="author"/>
            <span>{{ post.author }}</span>
          </div>
          <div class="text-info-date">{{ post.posted_at }}</div>
        </div>

        <div class="text-title">{{ post.title }}</div>

        <div v-html="post.content"></div>

      </div>
      <div class="imagem">
        <img
          v-if="post.files"
          :src="post.files[0].src"
          alt="Quem somos"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />

        <div class="post-info tags">
          <span>Tags</span>
          <ul>
            <li v-for="tag in post.tags" :key="tag">
              {{ tag }}
            </li>
          </ul>
        </div>
        <div class="post-info categories">
          <span>Categorias</span>
          <ul>
            <li v-for="category in post.categories" :key="category.id">
              {{ category.name }}
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div v-if="post.gallery && post.gallery.length" class="post-gallery page">
      <carrosel-gallery :gallery="post.gallery" />
    </div>
  
  </section>
</template>

<script>
import { api } from "@/services.js";
import CarroselGallery from "@/components/CarroselGallery.vue";

export default {
  name: "blog-post",

  components: {
    CarroselGallery
  },

  data() {
    return {
      post: {}
    };
  },

  methods: {
    async getPost(post_id) {
      await api
        .get("/posts/find", { params : { platform: "atheva_web", id: post_id }})
        .then(response => {
          this.post = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },

  created() {
    document.title = "Atheva | Blog Post";
    this.getPost(this.$route.params.id);
  },
};
</script>

<style scoped>
.container.page, .post-gallery {
  border-bottom: 1px solid #e0e0e0;
}

.post-gallery {
  max-width: 1140px;
  margin: 0 auto;
  padding: 10px;
}

.text-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  font-weight: 800;
  color: #232f3e;
}

.text-info-author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-info-author img {
  margin-right: 0.5rem;
  filter: brightness(0) saturate(100%) invert(80%) sepia(18%) saturate(5081%) hue-rotate(351deg) brightness(99%) contrast(96%);
}

.text-title {
  font-size: 27px;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #232f3e;
}

.post-info {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #f6f3ee;
  padding: 30px 25px;
  border-radius: 3px;
}

.post-info span {
  color: #232f3e;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 2rem;
}

.post-info span::after {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background: #f9b010;
  display: block;
  margin: 10px 0;
}

.post-info ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.post-info ul li {
  background-color: #232f3e;
  color: #f9b010;
  font-size: 14px;
  text-transform: capitalize;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
}

</style>

