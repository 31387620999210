<template>
  <section class="carrosel-gallery">
    <splide :options="options" @splide:mounted="onMounted" class="cards">
      <splide-slide
        class="card-item"
        v-for="item in gallery"
        :key="item.id"
        @click="openImage(item)"
      >
        <img :src="item.src" :alt="item.filename" @click="openImage(item)" />
      </splide-slide>

      <!-- <template v-slot:controls>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </template> -->
    </splide>
    <!-- <image-modal :image="show" /> -->
  </section>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import ImageModal from "@/components/ImageModal";

export default {
  name: "carrosel-gallery",

  components: {
    Splide,
    SplideSlide,
    // ImageModal,
  },

  props: {
    gallery: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      show: {},
      options: {
        type: "loop",
        // padding: {
        //   right: "5rem",
        //   left: "5rem",
        // },
        rewind: true,
        width: 1084,
        pagination: false,
        perPage: 3,
        perMove: 1,
        // gap: "1rem"
      }
    };
  },

  methods: {
    onMounted(slider) { 
      setTimeout(function () { slider.refresh(); }, 3000);
    },

    openImage(image) {
      this.show = image;
    }
  }
};
</script>

<style scoped>
.card-item {
  /* box-shadow: 0px 8px 16px #0000001a; */
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  /* min-width: 191.333; */
  margin-right: 27px;
  width: 191.333px !important;
  height: 162px;
  cursor: pointer;
}
.card-item img {
  width: 191.333px;
  height: 162px;
}
/* @media screen and (max-width: 768px) {
  section {
    grid-template-columns: 1fr;
    height: auto;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 300px;
  }
  .sub {
    max-width: 300px;
    font-size: 15px;
    margin-bottom: 40px;
  }
  .cards {
    width: 120%;
  }
  .splide,
  .splide__slide {
    position: initial;
  }
  .info h3 {
    font-size: 16px;
  }
  .info span,
  .info p {
    font-size: 15px;
  }
  .sub {
    font-size: 16px;
  }
  .footer {
    left: -40px;
    width: 150%;
  }
} */
</style>